<template>
  <app-module-view>
    <template slot="body">
      <weights-settings-filter></weights-settings-filter>
      <div class="card" v-if="isLoaded">
        <div class="card-header">
          {{ $t('performanceWeights.title') }}
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id="list-table" class="table table-striped">
              <thead>
              <tr>
                <th width="20">{{ $t('performanceWeights.name') }}</th>
                <th v-for="(weight, index) in weights" :key="`item-${index}`" style="align-content: center">
                  <app-input
                    v-model="weight.name"
                    type="text"
                    :id="'weights_name_' + weight.id"
                    :readonly="index === 0 && !isSuperAdmin"
                  >
                  </app-input>
                </th>
                <th v-if="weights.length <= 3">
                  <button
                    v-if="isCreateButtonVisible"
                    class="btn btn-success pull-right m-t-30"
                    @click.prevent="addWeights()"
                  >{{ $t('performanceWeights.add') }}
                  </button>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ $t('performanceWeights.periodFor') }}:</td>
                <td v-for="(weight, index) in weights" :key="`item-${index}`" style="text-align: center">
                  {{ weight.month + '/' + weight.year }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('performanceWeights.defaultPeriod') }}:</td>
                <td v-for="(weight, index) in weights" :key="`item-${index}`" style="text-align: center">
                  <input
                    :disabled="index === 0 && !isSuperAdmin"
                    name="defaultRadio"
                    class="defaultRadio"
                    v-model="defaultWeightsIndex"
                    type="radio"
                    :value="index"
                    :id="'weights_default_' + weight.id"
                  >
                </td>
              </tr>
              <template>
                <tr v-for="(weightName, weightKey) in $t('performanceWeights.tableListing')" :key="weightName + weightKey">
                  <td>{{ weightName }}</td>
                  <td v-for="(weight, index) in weights" :key="`item-${index}`">
                    <app-input
                      v-model="weight[weightKey]"
                      type="number"
                      :id="'weights_value_' + weight.id"
                      :step="0.1"
                      :min="0"
                      :max="100"
                      :readonly="index === 0 && !isSuperAdmin"
                    >
                    </app-input>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <div class="table-responsive">
              <button
                v-if="isSaveButtonVisible"
                class="btn btn-success pull-right m-t-30"
                @click.prevent="save()"
              >{{ $t('performanceWeights.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import Input from '../form/inputs/Input'
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import WeightsSettingsFilter from './WeightsSettingsFilter'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'WeightsSettings',
  data () {
    return {
      defaultWeightsIndex: 0,
      weightsId: '',
      isLoading: false,
      printArticlesDtConfig: {
        fields: {
          title: this.$t('printArticle.title'),
          orderDate: this.$t('article.order_date'),
          sharedUsers: this.$t('printArticle.sharedUsers'),
          type: this.$t('printArticle.type'),
          quality: this.$t('printArticle.quality')
        },
        render: {
          sharedUsers: (sharedUsers) => {
            return sharedUsers.map(userID => {
              return this.$store.getters['user/userById'](userID).username
            }).join(', ')
          }
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.EDITOR_PERFORMANCE_WEIGHTS_PERMISSIONS
    }
  },
  watch: {
    weightsId: function (val) {
      const displayWeights = this.weights.filter(obj => {
        return obj.weightsId === val
      })
      this.activateWeights(displayWeights[0])
    },
    defaultWeightsIndex: function (newIndex, oldIndex) {
      this.$store.dispatch('weights/toggleDefault', newIndex)
      this.$store.dispatch('weights/toggleDefault', oldIndex)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    addWeights () {
      this.$store.dispatch('weights/add')
    },
    async save () {
      this.$store.dispatch('weights/save')
        .then(() => {
          if (this.$store.getters['weights/error'] === null) {
            this.$store.dispatch('weights/fetch')
            this.defaultWeightsIndex = 0
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['scale/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapState([
      'callingAPI',
      'user'
    ]),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    weights () {
      return this.$store.getters['weights/weights']
    },
    isLoaded () {
      return this.$store.getters['weights/loaded']
    },
    isSuperAdmin () {
      // TODO CMS-163 change to permissions
      return false
    }
  },
  components: {
    WeightsSettingsFilter,
    appInput: Input,
    appModuleView: ModuleView,
    weightsSettingsFilter: WeightsSettingsFilter
  }
}
</script>

<style lang="scss" scoped>
.doughnutWrapper {
  height: 150px;
}

.defaultRadio {
  left: auto;
  opacity: 1;
}
</style>
